import React, { Component } from "react";
import html2canvas from "html2canvas";

class MemeGenerator extends Component {
  constructor(props) {
    super(props);
    // Prepopulate the allMemeImgs array with the image filenames
    this.state = {
      topText: "",
      bottomText: "",
      randomImg: "/images/Airdrop Kitten.png", // Initialize with the first image from your list
      allMemeImgs: [
        "Airdrop Kitten.png",
        "Awkard KittyD1.png",
        "Breaking Kitty Colore.jpg",
        "Chillin Face.PNG",
        "ChucklesD.jpg",
        "Crew KittiesTrans1.png",
        "Drinking JeetearsTrans.png",
        "Dumpster KittenTrans.png",
        "Gambling JakeDigital.png",
        "Geeked Face.PNG",
        "Jake Joking Digital.png",
        "Jake RugCD.png",
        "StandoffC.jpg",
        "Wut Face.PNG",
        "Yacht Characters Colored.jpg",
        // Add the rest of the images as necessary
      ].map((image) => `/images/${image}`), // Map the filenames to paths
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const randNum = Math.floor(Math.random() * this.state.allMemeImgs.length);
    const randMemeImg = this.state.allMemeImgs[randNum];
    this.setState({ randomImg: randMemeImg });
  }

  handleDownload() {
    html2canvas(document.querySelector(".meme")).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "meme.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  render() {
    return (
      <div>
        <form className="meme-form" onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="topText"
            placeholder="Top Text"
            value={this.state.topText}
            onChange={this.handleChange}
          />{" "}
          <br />
          <br />
          <input
            type="text"
            name="bottomText"
            placeholder="Bottom Text"
            value={this.state.bottomText}
            onChange={this.handleChange}
          />{" "}
          <br />
          <br />
          <button type="submit">CHANGE MEME</button>
          <button type="button" onClick={this.handleDownload}>
          Download Meme
        </button>
        </form>
      
        <div className="meme">
          <img
            src={this.state.randomImg}
            alt="meme"
            style={{ width: "100%", height: "auto" }}
          />
          <h2
            className="top"
            style={{
              position: "absolute",
              left: "50%",
              top: "5%",
              transform: "translate(-50%, -50%)",
              color: "white",
              WebkitTextStroke: "1px black",
            }}
          >
            {this.state.topText}
          </h2>
          <h2
            className="bottom"
            style={{
              position: "absolute",
              left: "50%",
              bottom: "5%",
              transform: "translate(-50%, -50%)",
              color: "white",
              WebkitTextStroke: "1px black",
            }}
          >
            {this.state.bottomText}
          </h2>
        </div>
      </div>
    );
  }
}

export default MemeGenerator;
