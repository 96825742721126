import React from "react";

function Header() {
  return (
    <header>
      <p>Just Kitten MEME MAKER</p>
    </header>
  );
}

export default Header;
